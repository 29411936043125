<template>
  <span>
    <!-- <button
      v-if="useLiveTerms"
      :id="`eventbrite-widget-modal-trigger-${eventId}`"
      type="button"
      class="btn btn-secondary px-4 text-uppercase text-white circular-black btn-event-trigger"
    >
      {{ $t("nav.LIT_RegisterNow") }}
    </button> -->
    <a
      id="purchase-now"
      href="https://loopcommunity.com/shop/tracks-made-easy-workshop---replay-30272"
      class="btn btn-secondary px-4 text-uppercase text-white circular-black btn-event-trigger"
    >
      {{ $t("nav.LIT_PurchaseNow") }}
    </a>
  </span>
</template>
<script>
import { AppConfig } from "@/config";

export default {
  props: {
    eventId: String,
  },
  data: () => ({
    useLiveTerms: AppConfig.UseLiveTerms,
  }),
  mounted() {
    console.log("mounting", this.eventId);
    if (this.useLiveTerms) {
      window.EBWidgets.createWidget({
        widgetType: "checkout",
        eventId: this.eventId,
        modal: true,
        modalTriggerElementId: `eventbrite-widget-modal-trigger-${this.eventId}`,
        onOrderComplete: () => {
          console.log("Order complete!");
        },
      });
    }
  },
};
</script>
<style>
.btn-event-trigger {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}
</style>
@/config
