<template>
  <section class="container mb-5">
    <div class="quote-wrapper mb-5">
      <img
        id="quotation-mark"
        src="/assets/img/quote.svg"
        :alt="$t('quote.LIT_LargeQuotationMark')"
      >
      <div class="text">
        <p class="h5 font-weight-light">
          {{ quote.text }}
        </p>

        <h1 class="h1 circular-black text-uppercase text-right mb-0 mt-3">
          “{{ quote.name }}”
        </h1>

        <div class="triangle" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      quoteIndex: 0
    };
  },
  computed: {
    quotes() { 
      return [{ 
        name: this.$t('quote.LIT_StephanieWesley'), 
        text: this.$t('quote.MES_StephanieWesleyMessage')
      },{ 
        name: this.$t('quote.LIT_MattReif'), 
        text: this.$t('quote.MES_MattReifMessage')
      }, 
      ];
    }, 
    quote() { 
      return this.quotes[this.quoteIndex];
    }
  },
  created() {
    this.quoteIndex = Math.floor(Math.random() * this.quotes.length);
  },
};
</script>


<style scoped lang="scss">
#quotation-mark {
  position: absolute;
  width: 75px;
  top: 75px;
  z-index: 101;
  left: 50px;
}
.container {
  max-width: 900px;
  padding: 0 25px;
  background: transparent;
}
.quote-wrapper {
  margin-top: 0px;
  border-left: 2px solid #fcd24b;
  padding-top: 100px;
  position: relative;
}

.text {
  padding: 75px 50px 50px;
  border: 2px solid #fcd24b;
  border-left: none;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 170px;
    top: -5px;
    left: 0px;
    border: 5px solid white;
  }
  &-right {
    text-align: right;
  }
}

.triangle:before {
  content: "";
  width: 0px;
  height: 0px;
  border: 50px solid;
  border-color: transparent #fcd24b transparent transparent;
  position: absolute;
  right: -12px;
  bottom: -51px;
  transform: scaleX(0.8);
  -webkit-transform: scaleX(0.8);
}

.triangle:after {
  content: "";
  width: 0px;
  height: 0px;
  border: 70px solid;
  border-color: transparent #fff transparent transparent;
  transform: scaleX(0.8);
  -webkit-transform: scaleX(0.8);
  position: absolute;
  right: -14px;
  bottom: -46px;
}
</style>