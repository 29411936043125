import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import VueI18n from 'vue-i18n'
import { messages } from './plugins/i18n'

Vue.config.productionTip = false;

Vue.use(VueI18n)

export const i18n = new VueI18n({ 
  locale: 'en-us',
  fallbackLocale: 'en-us',
  messages: messages,
});

export const app = new Vue({ 
  data: { 
    locale: 'en-us'
  },
  watch: { 
    $route(){ 
      this.updateLocale();
    }
  },
  mounted(){
    this.updateLocale();

  },
  methods:{ 
    updateLocale(){
      const locale = router.currentRoute.params?.locale || ''; 

      if (!locale){ 
        return;
      }
      
      if (!['en-us','es-mx'].includes(locale.toLowerCase())){
        router.push('/');
        return;
      }
      this.locale = locale;
      i18n.locale = locale;     
      document.querySelector('html').setAttribute('lang',locale);
    }
  },
  render: h => h(App), router, i18n
}).$mount('#app');

console.log('app',messages);
