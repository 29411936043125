<template>
  <section class="py-5 my-3 container px-4 px-md-auto">
    <h1 class="px-md-5 text-primary circular-bold text-center">
      {{ $t("needToKnow.MES_LearnEverythingAboutTracks") }}
    </h1>
    <p class="mt-4 font-weight-thin">
      {{
        useLiveTerms
          ? $t("needToKnow.MES_ChurchesEmbraceTechnology")
          : $t("needToKnow.MES_ChurchesEmbraceTechnologyReplay")
      }}
    </p>
  </section>
</template>

<script>
import { AppConfig } from "@/config";

export default {
  data() {
    return {
      useLiveTerms: AppConfig.UseLiveTerms,
    };
  },
};
</script>
<style lang="scss" scoped>
section.container {
  max-width: 1050px;
}
p {
  font-size: 1.1rem;
  line-height: 1.5;
}
</style>
@/config
