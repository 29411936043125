<template>
  <nav
    class="navbar sticky-top navbar-expand-lg navbar-light bg-white shadow-sm"
  >
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="/assets/img/loop.svg" :alt="$t('nav.LIT_Loop')" />
      </a>

      <div id="navbarTogglerDemo02" class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto mb-2 me-5 mb-lg-0">
          <li class="nav-item px-3">
            <a
              class="nav-link active"
              :href="`https://loopcommunity.com/${$root.locale}`"
              target="_blank"
              rel="noopener"
            >
              {{ $t("nav.LIT_LoopCommunityHome") }}
            </a>
          </li>
          <li class="nav-item px-3">
            <a class="nav-link" href="#resources-for-beginners">{{
              $t("nav.LIT_ResourcesForBeginners")
            }}</a>
          </li>
        </ul>
      </div>
      <EventBriteButton
        eventId="619450642547"
        :class="locale == 'es-mx' ? 'd-inline-block' : 'd-none'"
      />
      <EventBriteButton
        eventId="102426137334"
        :class="locale == 'en-us' ? 'd-inline-block' : 'd-none'"
      />
    </div>
  </nav>
</template>

<script>
import EventBriteButton from "./EventBriteButton.vue";

export default {
  data() {
    return {
      isPlaying: false,
      showVideo: false,
    };
  },
  computed: {
    locale() {
      return this.$root.locale;
    },
  },
  components: { EventBriteButton },
};
</script>

<style scoped lang="scss">
.nav-link {
  color: #2b2b2b !important;
  font-weight: bold;
}

@media only screen and (max-width: 410px) {
  img {
    scale: 0.7;
  }
  .container {
    display: flex;
    justify-content: center;
  }
}
</style>
