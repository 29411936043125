<template>
  <section class="bg-dark footer-container">
    <div class="container py-5 text-white">
      <div class="d-flex justify-content-between text-center">
        <span>
          <img
            src="/assets/img/logo-light.png"
            :alt="$t('footer.LIT_LoopLogo')"
            class="logo mb-2"
          />
          <p class="mb-0 d-lg-none">
            <small>
              {{ $t("footer.MES_LoopProvidesWorshipLeaders") }}
            </small>
          </p>
        </span>
        <span class="d-lg-flex align-items-center d-none">
          <a
            href="https://www.facebook.com/loopcommunity"
            target="_blank"
            rel="noopener"
          >
            <i class="fab fa-facebook-f ms-3">
              <span class="visually-hidden">
                {{ $t("footer.LIT_FacebookIcon") }}
              </span>
            </i>
          </a>
          <a
            href="https://www.instagram.com/loopcommunity/"
            target="_blank"
            rel="noopener"
          >
            <i class="fab fa-instagram ms-3">
              <span class="visually-hidden">{{
                $t("footer.LIT_InstagramIcon")
              }}</span>
            </i>
          </a>
          <a
            href="https://twitter.com/loopcommunity"
            target="_blank"
            rel="noopener"
          >
            <i class="fab fa-twitter ms-3">
              <span class="visually-hidden">{{
                $t("footer.LIT_TwitterIcon")
              }}</span>
            </i>
          </a>
          <a
            href="https://www.youtube.com/loopcommunity"
            target="_blank"
            rel="noopener"
          >
            <i class="fab fa-youtube ms-3">
              <span class="visually-hidden">{{
                $t("footer.LIT_YoutubeIcon")
              }}</span>
            </i>
          </a>
        </span>
      </div>
      <hr />
      <div
        class="d-flex justify-content-between align-items-center flex-column flex-md-row text-center flex-wrap flex-md-nowrap"
      >
        <small class="d-block order-2 mt-2 mt-md-0 order-md-1"
          >© {{ year }} {{ $t("footer.LIT_LoopCommunity") }}</small
        >
        <small class="d-block order-1 order-md-2">
          <a
            href="https://loopcommunity.com/en-us/help"
            target="_blank"
            rel="noopener"
            class="ms-3"
            >{{ $t("footer.LIT_HELP") }}</a
          >
          <a
            href="https://loopcommunity.com/en-us/faq"
            target="_blank"
            rel="noopener"
            class="ms-3"
            >{{ $t("footer.LIT_FAQ") }}</a
          >
          <a
            href="https://loopcommunity.com/en-us/community-groups"
            target="_blank"
            rel="noopener"
            class="ms-3"
            >{{ $t("footer.LIT_CommunityGroups") }}</a
          >

          <router-link
            v-if="locale === 'en-us'"
            class="link-style"
            to="/es-mx"
            @click.native="scroll"
          >
            Español
          </router-link>

          <router-link
            v-if="locale === 'es-mx'"
            class="link-style"
            to="/en-us"
            @click.native="scroll"
          >
            English
          </router-link>
        </small>
      </div>
    </div>
    <div class="build-info">
      {{ build }}
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getYear() + 1900,
      build: process.env.VUE_APP_BUILD_VERSION,
    };
  },
  computed: {
    locale() {
      return this.$root.locale;
    },
  },
  methods: {
    scroll() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  height: 1px;
  border: none;
  opacity: 1;
}
.logo {
  height: 40px;
}
.right {
  float: right;
}
a {
  text-decoration: none;
  color: white;
  i {
    color: #a2b1b4;
  }
}
.link-style {
  text-decoration: none;
  color: white;
  margin-left: 0.5rem;

  section {
    position: relative;
  }
  .build-info {
    position: absolute;
    bottom: 0em;
    left: 0em;
    right: 0em;
    font-size: 0.6em;
    text-align: center;
    color: #333;
  }
}
</style>
