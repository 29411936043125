<template>
  <section
    id="resources-for-beginners"
    class="py-5 mt-5 container px-0 px-md-2"
  >
    <h1 class="mt-5 text-primary circular-bold mb-4 text-center-mobile">
      {{ $t('resourcesForBeginners.LIT_MoreResourcesForBeginners') }}
    </h1>
    <div class="row d-none d-md-flex rows">
      <div
        v-for="(link) in links"
        :key="link.title"
        class="col-12 col-md-6 col-lg-3"
      >
        <a
          :href="link.link"
          class="circular-bold"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="link.img"
            :alt="link.imgAlt"
            class="w-100 mb-2"
          >
          {{ link.title }}

        </a>
      </div>
    </div>
    <div class="d-md-none mb-4">
      <VueSlickCarousel v-bind="settings">
        <div
          v-for="link in links"
          :key="link.title"
          class="px-2"
        >
          <a
            :href="link.link"
            class="circular-bold"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="link.img"
              :alt="link.imgAlt"
              class="w-100 mb-2"
            >
            {{ link.title }}
          </a>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        "centerMode": true,
        "centerPadding": "40px",
        "draggable": false
      },
      
    };
  },
  computed:{
    links(){
      return [
        {
          img: "/assets/img/link1.jpg",
          title: this.$t('resourcesForBeginners.LIT_HowToPurchaseTracksOnLoopCommunity'),
          link: "https://www.youtube.com/watch?v=fL9JPrFIjhE&feature=youtu.be",
          imgAlt: this.$t('resourcesForBeginners.LIT_PurchasingTracksOnLoopVideoLink')
        },
        {
          img: "/assets/img/link2.jpg",
          title: this.$t('resourcesForBeginners.LIT_HowToGetStartedWithTracks'),
          link:
            "https://loopcommunity.com/blog/2021/01/how-to-get-started-with-tracks/",
          imgAlt: this.$t('resourcesForBeginners.LIT_GettingStartedWithTracksOnLoopPageLink')

        },
        {
          img: "/assets/img/link3.jpg",
          title: this.$t('resourcesForBeginners.LIT_IfTherewasEverATimeToUseTracksNowIsTheTime'),
          link:
            "https://loopcommunity.com/blog/2020/04/if-there-was-ever-a-time-to-use-tracks-the-time-is-now/",
          imgAlt: this.$t('resourcesForBeginners.LIT_IfThereWasEverATimeToUseTracksNowIsTheTimePageLink')

        },
        {
          img: "/assets/img/link4.jpg",
          title: this.$t('resourcesForBeginners.LIT_HowTOLegallyStreamTracksInOnlineServices'),
          link: "https://www.youtube.com/watch?v=BJQ6CBHxBQA",
          imgAlt: this.$t('resourcesForBeginners.LIT_HowToLegallyStreamTracksVideoLink')

        },
      ];
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1050px;
}
a {
  text-decoration: none;
  color: #000;
}
</style>