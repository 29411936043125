<template>
  <section class="py-3 py-md-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <img
            src="/assets/img/what-to-expect.jpg"
            class="w-100 mt-4 pe-3 ps-md-0 pe-md-4 ps-3"
            :alt="$t('whatToExpect.LIT_GuitaristUsingLoop')"
          />
        </div>
        <div class="col-12 col-md-6 px-4 px-md-auto">
          <h1 class="circular-bold text-primary mb-4">
            {{ $t("whatToExpect.LIT_WhatToExpect") }}
          </h1>
          <ul class="triangle-bullets">
            <li>
              {{ $t("whatToExpect.MES_LearnEverythingAboutMultitracks") }}
            </li>
            <li>
              {{
                useLiveTerms
                  ? $t("whatToExpect.MES_LiveInteractiveClassroom")
                  : $t("whatToExpect.MES_InteractiveClassroom")
              }}
            </li>
            <li>
              {{ $t("whatToExpect.MES_GetCoachingFeedback") }}
            </li>
            <li>
              {{ $t("whatToExpect.MES_LearnFromHome") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AppConfig } from "@/config";

export default {
  data() {
    return {
      useLiveTerms: AppConfig.UseLiveTerms,
    };
  },
};
</script>

<style scoped>
section {
  background-color: #f0f5f7;
}
.container {
  max-width: 1050px;
}
li {
  font-size: 1.1rem;
  line-height: 1.5;
}
</style>
@/config
