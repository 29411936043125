<template>
  <div>Detecting Language</div>
</template>

<script>
export default {
  mounted(){ 
    
    //Navigator-Languages MDN docs: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages
    const preferredLocale = navigator.language;
    // 'es', 'es-mx', 'en','en-UK','en-US', 

    const language = preferredLocale.split('-')[0].toLowerCase();
    if (language === 'es'){
      this.$router.push('/es-mx');
    }else{
      this.$router.push('/en-us');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>