<template>
  <section style="background-image: url('/assets/img/who-bg.jpg')">
    <div class="container py-5">
      <h1 class="text-primary circular-bold text-center mt-4 mb-5">
        {{ $t("hostedBy.LIT_HostedBy") }}
      </h1>
      <div
        :class="
          locale === 'en-us' ? 'row gx-5' : 'row gx-5 justify-content-center'
        "
      >
        <div
          v-for="(host, i) in hosts"
          :key="i"
          :class="[
            i % 2 ? 'ps-md-5' : 'pe-md-5',
            'col-12 col-md-6 mb-4 mb-lg-0',
          ]"
        >
          <div class="row">
            <div class="col-12 col-lg-4">
              <img class="headshot w-100" :src="host.img" :alt="host.name" />
            </div>
            <div class="col-12 col-lg-8">
              <h2 class="text-primary circular-bold text-center-mobile">
                {{ host.name }}
              </h2>
              <p class="text-white font-weight-thin text-center-mobile">
                {{ host.bio }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    hosts() {
      if (this.$root.locale === "en-us") {
        return [
          {
            name: this.$t("hostedBy.LIT_MattMcCoy"),
            bio: this.$t(
              "hostedBy.MES_MattMcCoyIsAnAbleCertifiedTrainerAndFounder"
            ),
            img: "/assets/img/matt-mccoy.jpg",
          },
          {
            name: this.$t("hostedBy.LIT_DerekKerr"),
            bio: this.$t(
              "hostedBy.MES_DerekKerrIsACertifiedLoopTraineAndDirectorOfMarketing"
            ),
            img: "/assets/img/derek-kerr.jpg",
          },
        ];
      } else {
        return [
          {
            name: this.$t("hostedBy.LIT_MJMorales"),
            bio: this.$t("hostedBy.MES_MJMoralesBioMessage"),
            img: "/assets/img/mj-morales.jpg",
          },
        ];
      }
    },
    locale() {
      return this.$root.locale;
    },
  },
};
</script>

<style scoped>
section {
  background-position: center;
  background-size: cover;
}
.container {
  max-width: 1050px;
}
.headshot {
  border-radius: 50%;
  max-width: 140px;
  margin: 0 auto 1rem;
  display: block;
}
h2 {
  font-size: 1.25rem;
}
</style>
