//Vue.use(VueI18n, { bridge: true })

export const messages = {
  "en-us": {
    nav: {
      LIT_LoopCommunityHome: "Loop Community Home",
      LIT_ResourcesForBeginners: "Resources for beginners",
      LIT_RegisterNow: "Register Now",
      LIT_PurchaseNow: "Purchase Now",

      LIT_Loop: "Loop Logo",
    },
    hero: {
      LIT_Tracks: "Tracks",
      LIT_MadeEasy: "Made Easy",
      LIT_LiveOnlineWorkshop: "Online Workshop",
      LIT_OnlineWorkshopReplay: "Online Workshop - Replay",
      LIT_WatchTheVideo: "Watch the video",

      LIT_PlayVideo: "Play Video",
      LIT_GroupWorkshopOnline: "Group workshop online",
    },
    needToKnow: {
      MES_LearnEverythingAboutTracks:
        "Learn Everything You Need to Know About Using Tracks in Worship",
      MES_ChurchesEmbraceTechnology:
        "Churches are needing to embrace technology more than ever. Because of that, Loop Community has created an online workshop that will teach you everything you need to know about using tracks in worship! The Tracks Made Easy Workshop is a 2-hour course that will walk you through the essentials of using tracks in worship. After the workshop, you'll have the knowledge and training you need to begin using tracks at your church.",
      MES_ChurchesEmbraceTechnologyReplay:
        "Churches are needing to embrace technology more than ever. Because of that, Loop Community has created an online workshop that will teach you everything you need to know about using tracks in worship! The Tracks Made Easy Workshop is a 2-hour course that will walk you through the essentials of using tracks in worship. After the workshop, you'll have the knowledge and training you need to begin using tracks at your church.",
    },
    whatToExpect: {
      LIT_WhatToExpect: "What to Expect",
      MES_LearnEverythingAboutMultitracks:
        "Learn everything you need to know about using multitracks in worship",
      MES_LiveInteractiveClassroom:
        "Sessions taught by Loop Community Founder Matt McCoy and Prime Certified Trainer Derek Kerr",
      MES_InteractiveClassroom:
        "Interactive classroom sessions with Matt McCoy and Derek Kerr",
      MES_GetCoachingFeedback:
        "Short videos on each topic so you can easily return to the information later",
      MES_ConnectWithOtherWorshipLeaders:
        "Connect with other worship leaders around the world",
      MES_LearnFromHome:
        "Learn from the comfort of your home office or living room",

      LIT_GuitaristUsingLoop: "A guitarist using Loop",
    },
    attendeesReceive: {
      LIT_AllAttendeesReceive: "All attendees receive:",
      LIT_1FreeTrackOfTheirChoice: "1 free multitrack of their choice",
      MES_ExclusiveDiscounts:
        "Exclusive discounts on Loop Community Hardware (Track Rig & Looptimus)",
      MES_PlusLoopCertificate:
        'PLUS a "Loop Certified" certificate showing that you completed the workshop',

      LIT_DrummerUsingLoop: "A drummer Using Loop",
    },
    whatYoullLearn: {
      LIT_WhatYoullLearn: "What You'll Learn",
      item2: "The History of Tracks",
      item4: "Running tracks from a simple and free mobile app (Prime)",
      item5: "Audio routing techniques (basic and advanced)",
      item6: "Customizing tracks (Changing keys, tempo, arrangements, etc.)",
      item7: "Being spontaneous with Multi Tracks",
      item8: "Using MIDI foot controllers",
      item9: "Syncing lyrics with your tracks using MIDI Out",
      item10: "Creating a setlist that flows",
      item11: "Sharing setlists with band members",
      item12: "Syncing your tracks with your chord charts",
    },
    quote: {
      LIT_StephanieWesley: "Stephanie Wesley",
      MES_StephanieWesleyMessage:
        "I've known about Loop Community for a while, and wondered if it could be a potential fit for my small music team; but I honestly couldn't figure out exactly how it all worked. Then five members of my team attended the online workshop and found it SUPER helpful. We came away from that workshop totally on board with Loop Community, and are deeply grateful to have ongoing access to ask questions along the way.",
      LIT_MattReif: "Matt Reif",
      MES_MattReifMessage:
        "I was MD-ing at my church when we decided we wanted to modernize our worship, we just didn't know how. After attending Loop Community's Tracks Made Easy Workshop, I can say without hesitation it's the absolute best starting point for any worship leader thinking about integrating tracks. I went from clueless to teaching my entire worship community how to use tracks after a few hours.",

      LIT_LargeQuotationMark: "Large quotation mark",
    },
    resourcesForBeginners: {
      LIT_MoreResourcesForBeginners: "More Resources for Beginners",
      LIT_HowToPurchaseTracksOnLoopCommunity:
        "How to purchase tracks on Loop Community",
      LIT_HowToGetStartedWithTracks: "How to get started with Tracks",
      LIT_IfTherewasEverATimeToUseTracksNowIsTheTime:
        "If there was ever a time to use tracks, the time is now",
      LIT_HowTOLegallyStreamTracksInOnlineServices:
        "How to legally stream tracks in online worship services",

      LIT_PurchasingTracksOnLoopVideoLink:
        "Purchasing Tracks on Loop video link",
      LIT_GettingStartedWithTracksOnLoopPageLink:
        "Getting started with tracks on Loop page link",
      LIT_IfThereWasEverATimeToUseTracksNowIsTheTimePageLink:
        "If there was ever a time to use tracks, now is the time page link",
      LIT_HowToLegallyStreamTracksVideoLink:
        "How to legally stream tracks video link",
    },
    hostedBy: {
      LIT_HostedBy: "Hosted By",
      LIT_MattMcCoy: "Matt McCoy",
      MES_MattMcCoyIsAnAbleCertifiedTrainerAndFounder:
        "Matt McCoy is an Ableton Certified Trainer and the Founder of LoopCommunity.com. He enjoys teaching churches, worship leaders and touring artists about using technology in worship. He has been using tracks in worship for over 15 years and invented the Looptimus Foot Controller, Prime Playback App and Track Rig audio output interface.",
      LIT_DerekKerr: "Derek Kerr",
      MES_DerekKerrIsACertifiedLoopTraineAndDirectorOfMarketing:
        "Derek Kerr is a certified Loop Trainer and the Director of Marketing at Loop Community. He joined the company in 2017 after graduating with a bachelor's degree in Public Relations. He currently lives in Chicago.",

      LIT_ImageOfMattMcCoy: "Image of Matt McCoy",
      LIT_ImageOfDerekKerr: "Image of Derek Kerr",
    },
    footer: {
      LIT_LoopCommunity: "Loop Community",
      LIT_HELP: "Help",
      LIT_FAQ: "FAQ",
      LIT_CommunityGroups: "Community Groups",
      MES_LoopProvidesWorshipLeaders:
        "Loop provides worship leaders with quality and affordable tracks, hardware and software for worship. We make complicated things simple so you can focus on what matters.",

      LIT_LoopLogo: "Loop Logo",

      LIT_FacebookIcon: "facebook icon",
      LIT_InstagramIcon: "instagram icon",
      LIT_TwitterIcon: "twitter icon",
      LIT_YoutubeIcon: "youtube icon",
    },
  },
  "es-mx": {
    nav: {
      LIT_LoopCommunityHome: "Inicio Loop Community",
      LIT_ResourcesForBeginners: "Recursos para principiantes",
      LIT_RegisterNow: "Regístrate Ahora",
      LIT_PurchaseNow: "Cómpralo Ahora",

      LIT_Loop: "Loop",
    },
    hero: {
      LIT_Tracks: "Secuencias",
      LIT_MadeEasy: "De Manera Fácil ",
      LIT_LiveOnlineWorkshop: "Taller en Línea en Vivo",
      LIT_OnlineWorkshopReplay: "Taller en Línea - Repetición",
      LIT_WatchTheVideo: "Ver el video",

      LIT_PlayVideo: "Reproduce El Video",
      LIT_GroupWorkshopOnline: "Taller grupal en línea",
    },
    needToKnow: {
      MES_LearnEverythingAboutTracks:
        "Aprende todo lo que necesitas saber sobre el uso de secuencias en la adoración",
      MES_ChurchesEmbraceTechnology:
        "Las iglesias necesitan adoptar la tecnología más que nunca.  Por eso, Loop Community está organizando un taller en línea que le enseñará todo lo que necesita saber sobre el uso de secuencias en la adoración.  El taller Secuencias de Manera Fácil es un evento de entrenamiento en vivo de 3 horas que lo guiará a través de los aspectos esenciales del uso de secuencias en la adoración.  Después del taller, tendrá el conocimiento y la capacitación que necesitas para comenzar a utilizar secuencias en su iglesia.",
      MES_ChurchesEmbraceTechnologyReplay:
        "Las iglesias necesitan adoptar la tecnología más que nunca.  Por eso, Loop Community está organizando un taller en línea que le enseñará todo lo que necesita saber sobre el uso de secuencias en la adoración.  El taller Secuencias de Manera Fácil es un evento de entrenamiento de 3 horas que lo guiará a través de los aspectos esenciales del uso de secuencias en la adoración.  Después del taller, tendrá el conocimiento y la capacitación que necesitas para comenzar a utilizar secuencias en su iglesia.",
    },
    whatToExpect: {
      LIT_WhatToExpect: "Que Esperar",
      MES_LearnEverythingAboutMultitracks:
        "Aprenda todo lo que necesita saber sobre el uso de secuencias en la adoración",
      MES_LiveInteractiveClassroom:
        "Sesiones de clase interactivas en vivo con MJ Morales",
      MES_InteractiveClassroom: "Sesiones de clase interactivas con MJ Morales",
      MES_GetCoachingFeedback:
        "Obtenga asesoramiento y respuesta al instante sobre sus preguntas en un salón de clases",
      MES_ConnectWithOtherWorshipLeaders:
        "Conéctate con otros líderes de adoración alrededor del mundo",
      MES_LearnFromHome:
        " Aprenda desde la comodidad de su casa, oficina o sala de estar",

      LIT_GuitaristUsingLoop: "Un guitarrista usando loop",
    },
    attendeesReceive: {
      LIT_AllAttendeesReceive: "Todos los asistentes reciben:",
      LIT_1FreeTrackOfTheirChoice: "1 secuencia gratis de su elección",
      MES_ExclusiveDiscounts:
        "Descuentos exclusivos en los equipos de Loop Community  (Track Rig y Looptimus)",
      MES_PlusLoopCertificate:
        'MÁS un certificado "Loop Certified" que demuestra que completó el taller',
      LIT_DrummerUsingLoop: "Un Baterista usando loop",
    },
    whatYoullLearn: {
      LIT_WhatYoullLearn: "Lo Que Aprenderás",
      item2: "La historia de las secuencias",
      item3: "Ableton Live",
      item4:
        "Correr tus secuencias desde una aplicación móvil simple y gratuita (Prime)",
      item5: "Técnicas de enrutamiento de audio (básicas y avanzadas)",
      item6:
        "Personalización de secuencias (Cambiar claves, tempo, arreglos, etc.)",
      item7: "Ser espontáneo al utilizar secuencias ",
      item8: "Uso de controladores midi con el pie ",
      item9: "Sincronización de letras con tus secuencias usando Salidas Midi",
      item10: "Crear una lista de canciones con fluidez ",
      item11: "Compartir listas de canciones con miembros de la banda",
      item12: "Subir tus propias secuencias",
    },
    quote: {
      LIT_StephanieWesley: "STEPHANIE WESLEY",
      MES_StephanieWesleyMessage:
        "Conozco Loop Community desde hace un tiempo y me preguntaba si podría ser una opción potencial para mi pequeño equipo de música;  pero honestamente no pude entender exactamente cómo funcionaba todo.  Luego, cinco miembros de mi equipo asistieron al taller en línea y lo encontraron SÚPER útil.  Salimos de ese taller totalmente de acuerdo con Loop Community, y estamos profundamente agradecidos de tener acceso continuo para hacer preguntas en el camino",
      LIT_LargeQuotationMark: "Comillas grandes",
      LIT_MattReif: "MATE REIF",
      MES_MattReifMessage:
        "Estaba trabajando como MD en mi iglesia cuando decidimos que queríamos modernizar nuestra adoración, pero no sabíamos cómo.  Después de asistir al taller Secuencias De Manera Fácil de Loop Community, puedo decir sin dudarlo que es el mejor punto de partida para cualquier líder de adoración que esté pensando en integrar secuencias.  Pasé de no tener ni idea a enseñarle a toda mi comunidad de adoración cómo utilizar las secuencias después de unas horas.",
    },
    resourcesForBeginners: {
      LIT_MoreResourcesForBeginners: "Más recursos para principiantes",
      LIT_HowToPurchaseTracksOnLoopCommunity:
        "Cómo comprar secuencias en Loop Community",
      LIT_HowToGetStartedWithTracks: "Cómo empezar con las secuencias ",
      LIT_IfTherewasEverATimeToUseTracksNowIsTheTime:
        "Si alguna vez hubo un momento para usar secuencias, , ahora es el momento",
      LIT_HowTOLegallyStreamTracksInOnlineServices:
        "Cómo transmitir legalmente utilizando secuencias en los servicios de adoración en línea",

      LIT_PurchasingTracksOnLoopVideoLink:
        "Compra las secuencias en el enlace del video de Loop",
      LIT_GettingStartedWithTracksOnLoopPageLink:
        "Introducción a las secuencias en el enlace de la página de Loop ",
      LIT_IfThereWasEverATimeToUseTracksNowIsTheTimePageLink:
        "Si alguna vez hubo un momento para utilizar secuencias , ahora es el tiempo link en la página",
      LIT_HowToLegallyStreamTracksVideoLink:
        "Cómo transmitir legalmente las secuencias enlace en el video",
    },
    hostedBy: {
      LIT_HostedBy: "Presentado Por:",
      LIT_MJMorales: "MJ Morales",
      MES_MJMoralesBioMessage:
        "MJ Morales es un músico y productor de Puerto Rico.  Es músico por mas de 30 años. Toca la guitarra, la batería y el bajo y ha colaborado con algunos de los artistas mas conocidos en la comunidad de adoración.  Le apasiona profundamente producir música de adoración, enseñar y servir a la iglesia local.",
      LIT_ImageOfMJMorales: "Imagen de MJ Morales",
    },
    footer: {
      LIT_LoopCommunity: "Loop Community",
      LIT_HELP: "Ayuda",
      LIT_FAQ: "PP. FF.",
      LIT_CommunityGroups: "Grupos Comunitarios",
      MES_LoopProvidesWorshipLeaders:
        "Loop proporciona a los líderes de adoración secuencias,, equipos y software asequibles y de calidad para la adoración.  Hacemos que las cosas complicadas sean simples para que puedas concentrarte en lo que importa.",

      LIT_LoopLogo: "Loop Logo",

      LIT_FacebookIcon: "icono de facebook",
      LIT_InstagramIcon: "icono de instagram",
      LIT_TwitterIcon: "icono de Twitter",
      LIT_YoutubeIcon: "icono de youtube",
    },
  },
};
