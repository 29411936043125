<template>
  <section
    class="learn-wrapper py-lg-5"
    style="background-image: url('/assets/img/what-youll-learn-bg.jpg')"
  >
    <div class="container py-5">
      <h1 class="text-center circular-bold text-primary my-4">
        {{ $t("whatYoullLearn.LIT_WhatYoullLearn") }}
      </h1>
      <vue-custom-scrollbar class="scroll-area" :settings="settings">
        <div id="learn-bullets" class="text-white h4 px-4 px-md-auto">
          <ol>
            <li>{{ $t("whatYoullLearn.item2") }}</li>
            <li v-if="$root.locale === 'es-mx'">
              {{ $t("whatYoullLearn.item3") }}
            </li>
            <li>{{ $t("whatYoullLearn.item4") }}</li>
            <li>{{ $t("whatYoullLearn.item5") }}</li>
            <li>{{ $t("whatYoullLearn.item6") }}</li>
            <li>{{ $t("whatYoullLearn.item7") }}</li>
            <li>{{ $t("whatYoullLearn.item8") }}</li>
            <li>{{ $t("whatYoullLearn.item9") }}</li>
            <li>{{ $t("whatYoullLearn.item10") }}</li>
            <li>{{ $t("whatYoullLearn.item11") }}</li>
            <li>{{ $t("whatYoullLearn.item12") }}</li>
          </ol>
        </div>
      </vue-custom-scrollbar>
    </div>
  </section>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  components: { vueCustomScrollbar },
  data: () => ({
    settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: true,
      tagname: "div",
    },
  }),
};
</script>

<style scoped lang="scss">
.learn-wrapper {
  background-size: cover;
  background-position: center 66%;
}
.container {
  max-width: 1050px;
}
#learn-bullets {
}
@media only screen and (max-width: 600px) {
  #learn-bullets {
    height: auto;
  }
}
ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}
ol li {
  counter-increment: my-awesome-counter;
  margin-left: 1.5rem;
  position: relative;
  vertical-align: middle;
  margin-top: 1rem;
  min-height: 2rem;
}
ol li::before {
  content: counter(my-awesome-counter);
  color: black;
  font-weight: bold;
  background: #fcd24b;
  border-radius: 50px;
  display: block;
  position: absolute;
  text-align: center;
  padding: 0.25rem;
  left: -3.5rem;
  width: 2rem;
  font-size: 1.25rem;
  font-family: "circular-std-black";
}
.ps__rail-y,
.ps__rail-y:hover {
  opacity: 1 !important;
  background: #252525 !important;
  width: 8px !important;
}
.ps__thumb-y {
  background-color: #e3ecee !important;
  border-radius: 0 !important;
  width: 8px !important;
  right: 0;
}
</style>
