<template>
  <section>
    <div class="hl" />
    <div class="vl d-none d-lg-block" />
    <div class="container">
      <img
        src="/assets/img/video-group.jpg"
        class="video-group-backdrop"
        :alt="$t('hero.LIT_GroupWorkshopOnline')"
      />

      <div class="hero-items">
        <div id="tracks-made-easy">
          <div>
            <span class="yellow-box" />
            <h1 class="circular-black">
              {{ $t("hero.LIT_Tracks") }}<br />{{ $t("hero.LIT_MadeEasy") }}
            </h1>
          </div>
          <h2 class="circular-book">
            {{
              useLiveTerms
                ? $t("hero.LIT_LiveOnlineWorkshop")
                : $t("hero.LIT_OnlineWorkshopReplay")
            }}
          </h2>
        </div>

        <div id="hero-video">
          <h3 class="text-right text-primary circular-bold">
            {{ $t("hero.LIT_WatchTheVideo") }}
          </h3>
          <div class="embed-responsive embed-responsive-16by9">
            <img
              v-if="!isPlaying"
              src="/assets/img/tracks-made-easy-video-preview.jpg"
              :alt="$t('hero.LIT_PlayVideo')"
              class="embed-responsive-item"
              tabindex="0"
              @click="isPlaying = true"
              @keydown="showVideo = true"
            />
            <iframe
              v-if="isPlaying || showVideo"
              width="560"
              height="315"
              :src="
                locale === 'en-us'
                  ? 'https://www.youtube.com/embed/YZBuy7N0UoU?autoplay=1'
                  : 'https://www.youtube.com/embed/hRpKO5tzFEo?autoplay=1'
              "
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              class="embed-responsive-item"
              allowfullscreen
              tabindex="0"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AppConfig } from "@/config";

export default {
  data() {
    return {
      isPlaying: false,
      showVideo: false,
      useLiveTerms: AppConfig.UseLiveTerms,
    };
  },
  computed: {
    locale() {
      return this.$root.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background-color: #f0f5f7;
  position: relative;
  overflow: hidden;
  min-height: 500px;
  padding-bottom: 2rem;
  .hero-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #tracks-made-easy {
    width: 524px;
    position: relative;
    margin-top: 100px;
    h1 {
      z-index: 150;
      position: relative;
      font-size: 6rem;
      padding: 1rem 0 1.5rem;
      line-height: 1;
    }
    .yellow-box {
      position: absolute;
      background-color: #ffd41d;
      height: 232px;
      width: calc(100% - 50px);
      z-index: 100;
      right: 12px;
    }
  }
  .video-group-backdrop {
    position: absolute;
    left: 65%;
  }
  .embed-responsive-16by9 {
    &:before {
      display: block;
      content: "";
      padding-top: 56.25%;
    }
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    background: #0c0c0c;
    img {
      cursor: pointer;
    }
  }
  .embed-responsive-item {
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    border: 0;
    width: 100%;
    height: 100%;
  }
  .row {
    position: relative;
    min-height: 500px;
    > div > div {
      position: relative;
      h1 {
        z-index: 150;
        position: relative;
        font-size: 6rem;
        padding: 1rem 0 1.5rem;
        line-height: 1;
      }
    }
    .yellow-box {
      position: absolute;
      background-color: #ffd41d;
      height: 100%;
      width: calc(100% - 50px);
      z-index: 100;
      right: 0;
    }
  }
}
.vl,
.hl {
  position: absolute;
}
.vl {
  border-left: 5px solid #ffd41d;
  height: 15%;
  left: 10%;
  bottom: 0;
}
.hl {
  border-top: 5px solid #ffd41d;
  width: 20%;
  left: 0;
  top: 5%;
}

.text-right {
  text-align: right;
}

@media only screen and (max-width: 521px) {
  h1 {
    font-size: 4rem !important;
  }
  h2 {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 400px) {
  h2 {
    margin-top: 10rem;
  }
}

@media only screen and (max-width: 991px) {
  #tracks-made-easy h3 {
    padding-left: 2.2rem;
  }
  #hero-video {
    margin-top: 4rem;
    padding: 0 2rem;
    width: 100%;
    h3 {
      text-align: center;
    }
  }
}

@media only screen and (min-width: 767px) {
  .video-group-backdrop {
    left: 46% !important;
  }
}

@media only screen and (min-width: 992px) {
  #hero-video {
    margin-top: calc(100px - 2rem);
    margin-right: -45px;
    .embed-responsive-16by9 {
      max-width: 412px;
    }
    h3 {
      margin-right: 36px;
    }
    height: 232px;
    width: 450px;
  }
  .hl {
    top: 10% !important;
  }
  #tracks-made-easy {
    left: -2%;
    h2 {
      text-align: center;
    }
  }
}

@media only screen and (min-width: 1200px) {
  #hero-video {
    margin-right: 0px;
  }
  #tracks-made-easy {
    left: 9%;
  }
}

@media only screen and (min-width: 1400px) {
  #hero-video {
    margin-right: 100px;
  }
}
</style>
@/config
