<template>
  <div>
    <Navbar />
    <Hero />
    <NeedToKnow />
    <WhatToExpect />
    <AttendeesReceive />
    <WhatYoullLearn />
    <Quote />
    <ResourcesForBeginners />
    <HostedBy />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Hero from "../components/Hero.vue";
import NeedToKnow from '../components/NeedToKnow.vue';
import WhatToExpect from "../components/WhatToExpect.vue";
import AttendeesReceive from "../components/AttendeesReceive.vue";
import WhatYoullLearn from "../components/WhatYoullLearn.vue";
import Quote from "../components/Quote.vue";
import ResourcesForBeginners from "../components/ResourcesForBeginners.vue";
import HostedBy from "../components/HostedBy.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Navbar,
    Hero,
    NeedToKnow,
    WhatToExpect,
    AttendeesReceive,
    WhatYoullLearn,
    Quote,
    ResourcesForBeginners,
    HostedBy,
    Footer,
  },
};

</script>

<style lang="scss" scoped>

</style>