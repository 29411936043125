import VueRouter from 'vue-router';
import Vue from 'vue';
import Home from './pages/Home';
import DetectLanguage from './pages/DetectLanguage';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
      { path: '/', component: DetectLanguage},
      { path: '/:locale', component: Home },
      { path:'*', redirect:'/'}
    ],
  });

export default router